import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo/seo'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="ef-container">
      <section className="ef-section">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <p>
          <Link to="/" className="ef-button -primary">
            Go back home
          </Link>
        </p>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
